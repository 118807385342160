var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"hero is-small"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"columns is-multiline is-centered is-vcentered"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text text-overline"},[_vm._v(" Zyndex is the Ultimate Destination for Google Drive Index which is Easily Deployable and has More Reliable Security Which can be Trusted. ")]),_c('p',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$vuetify.goTo('#scroll-target')}}},[_c('v-icon',[_vm._v("mdi-book-open-outline")]),_vm._v(" Read More ")],1)],1)])])])])]),_c('v-card',{staticClass:"ma-4",attrs:{"elevation":"20"}},[_c('section',{class:'hero ' + (_vm.$vuetify.theme.dark ? ' ' : 'gradient-hero') + ' is-small'},[_c('div',{staticClass:"hero-body"},[(_vm.currHerosrc)?_c('div',{staticClass:"columns is-multiline is-centered is-vcentered"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{key:_vm.herokey,staticClass:"column grad-text is-half"},[_c('div',{staticClass:"container"},[_c('div',{class:(_vm.ismobile ? 'text-center' : 'text-right') +
                    ' my-2' +
                    (_vm.ismobile ? ' text-h5' : ' text-h2') +
                    ' break-only-word font-weight-bold'},[_vm._v(" "+_vm._s(_vm.currHerosrc.title)+" ")]),_c('div',{class:(_vm.ismobile ? 'text-center' : 'text-right') +
                    ' my-1 text-subtitle font-weight-black'},[_vm._v(" "+_vm._s(_vm.currHerosrc.subtitle)+" ")])])])]),_c('div',{staticClass:"column is-half"},[_c('v-avatar',{attrs:{"color":"red","size":_vm.ismobile ? 300 : 500}},[_c('v-img',{attrs:{"src":"https://media.giphy.com/media/RKprmbDQXHFVy4DFyq/giphy-downsized.gif"}})],1)],1)],1):_vm._e()])])]),_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.ismobile ? 12 : 6}},[_c('v-card',{attrs:{"shaped":"","outlined":""}},[_c('v-col',{class:_vm.ismobile ? 'text-body-2' : 'text-overline' + ' font-weight-bold',attrs:{"align":"center","cols":"12"}},[_vm._v(" Deploy it Right Now by Clicking the Following Button and Get Started with the Instructions. Let's Get You Started ")]),_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-btn',{staticClass:"back-gif text text--black",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.$router.push('/generate')}}},[_c('v-icon',[_vm._v("mdi-check-all")]),_vm._v(" Generate Now ")],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"container",attrs:{"id":"scroll-target"}},[_c('div',{staticClass:"text text-h6 my-2 font-weight-bold"},[_vm._v(" Get to Know About the Project ")]),_c('div',{class:_vm.ismobile
          ? 'columns is-multiline is-mobile is-centered is-vcentered has-text-centered'
          : 'columns is-multiline is-mobile is-centered is-vcentered'},_vm._l((_vm.abtBtns),function(btn,index){return _c('div',{key:index,class:_vm.ismobile
            ? index == _vm.abtBtns.length - 1
              ? 'column is-full'
              : 'column is-half'
            : 'column is-2'},[_c('v-btn',{attrs:{"color":"primary"}},[_c('v-icon',[_vm._v(_vm._s(btn.icon))]),_vm._v(" "+_vm._s(btn.btnTxt)+" ")],1)],1)}),0)]),_c('v-container',{staticClass:"container mt-3"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text text-h6 my-2 font-weight-bold"},[_vm._v("Overview")]),_c('div',{staticClass:"text-body-2"},[_vm._v(" Zyndex is a Google Drive Index Combining the power of Cloudflare Workers and Google Drive which allows you to index your files on the browser through Cloudflare Workers. Zyndex Gives you Unmatched Amount of Customizations that no One Can Give. With Security as the Main Focus, This Gives You Every Thing You need to Manage Users. Complete Role Based Authentication and Authorization. Tokenized Download Links & Secured Playback can be one of the Prominent Features of this Index. ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }