<template>
  <v-footer elevation="15" inset dense small rounded shaped>
    <v-row align="center" justify="center" class="my-1 py-0">
      <v-row>
        <v-col align="center" justify="center">
          <v-icon>mdi-copyright</v-icon> {{ new Date().getFullYear() }} | Z Y N
          D E X
        </v-col>
      </v-row>
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
