import Vue from 'vue';
import Router from 'vue-router';
import goTo from 'vuetify/es5/services/goto';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  mode: 'history',
  routes,
});

export default router;
